* {
  box-sizing: border-box
}

body {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 26px;
}

.banner-text-slide .splide__arrows {
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.6);
  width: 64px;
  position: absolute;
  top: -25px;
  bottom: -25px;
  right: -88px;
  align-items: center;
}

.banner-text-slide .splide__arrow {
  position: static;
  height: 50%;
  background-color: transparent;
  transform: none;
  width: 100%;
  padding: 0;
}

.banner-text-slide .splide__arrow svg {
  fill: #fff
}

.banner-text-slide .splide__slide {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
}
.link {
  color: #007bff;
}
