@import url(./assets/fonts/inter/stylesheet.css);

* {
  font-family: "Inter", sans-serif;
}

body {
  margin: 0;
  color: rgba(18, 41, 69, 0.6);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.splide__slide {
  align-items: center;
  display: flex;
}
.anaylsisSlider .splide__slide  {
  flex-direction: column;
}
.anaylsisSlider  .splide__arrow--prev, .anaylsisSlider  .splide__arrow--next {
  bottom: 200px;
  top: inherit;
  width: 40px;
  height: 40px;
  opacity: 1;
  background-color: #fff;
}
.anaylsisSlider  .splide__arrow--prev {
  left: inherit;
  right: 300px;
}
.anaylsisSlider  .splide__arrow--next {
  left: inherit;
  right: 210px;
}
.anaylsisSlider .splide__arrow svg {
  fill: #225B7B;
}
@media (max-Width: 767px) {
  .splide__slide img {
    max-width: 100%;
    margin:  auto;

  }
  .anaylsisSlider  .splide__arrow--prev, .anaylsisSlider  .splide__arrow--next {
    bottom: 135px;
  }
  .anaylsisSlider  .splide__arrow--prev {
    left: inherit;
    right: 50px;
  }
  .anaylsisSlider  .splide__arrow--next {
    left: inherit;
    right: 00px;
  }
}

@media (max-Width: 992px) {
  .anaylsisSlider  .splide__arrow--prev, .anaylsisSlider  .splide__arrow--next {
    bottom: 155px;
  }
  .anaylsisSlider  .splide__arrow--prev {
    left: inherit;
    right: 150px;
  }
  .anaylsisSlider  .splide__arrow--next {
    left: inherit;
    right: 80px;
  }
}

@media (max-Width: 600px) {
  .anaylsisSlider  .splide__arrow--prev, .anaylsisSlider  .splide__arrow--next {
    bottom: 112px;
  }
  .anaylsisSlider  .splide__arrow--prev {
    left: inherit;
    right: 22%;
  }
  .anaylsisSlider  .splide__arrow--next {
    left: inherit;
    right: 3%;
  }
 }

/* html, body, #root, #root > div {
  height: 100%;
}
.App {
  min-height: 100%;
} */
